import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const savedLanguage = localStorage.getItem("language") || "es";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        dashboard: "Dashboard",
        products: "Products",
        orders: "Orders",
        app_code: "App Code",
        number: "Number",
        order_number: "Order Number",
        customer: "Customer",
        order_type: "Order Type",
        transport: "Transport",
        observation: "Observation",
        sold: "Sold",
        date: "Date",
        quantity: "Quantity",
        yes: "Yes",
        no: "No",
        product_list: "List of Products",
        create_product: "Create New Product",
        categories: "Categories",
        brand_list: "List of Brands",
        create_brand: "Create New Brand",
        users: "Users",
        user_list: "List of Users",
        create_user: "Create New User",
        total_auto_parts: "Total Auto Parts",
        orders_completed: "Orders Completed",
        pending_orders: "Pending Orders",
        recent_activity: "Recent Activity",
        description: "Description",
        order_completed: "Order #{{orderId}} completed",
        new_auto_part_added: "New auto part added: {{part}}",
        order_pending: "Order #{{orderId}} pending",
        code: "Code",
        alt_code: "Alternative Code",
        price: "Price",
        stock: "Stock",
        min_quantity: "Minimum Quantity",
        brand: "Brand",
        actions: "Actions",
        edit: "Edit",
        delete: "Delete",
        loading: "Loading...",
        fetch_error: "Failed to load products. Please try again later.",
        delete_error: "Failed to delete. Please try again later.",
        confirm_delete: "Are you sure you want to delete it?",
        select_brand: "Select a brand",
        edit_product: "Edit Product",
        save_changes: "Save Changes",
        brands: "Brands",
        id: "ID",
        name: "Name",
        fetch_brands_error: "Failed to load brands. Please try again later.",
        delete_brand_error: "Failed to delete brand. Please try again later.",
        edit_brand: "Edit Brand",
        brand_name: "Brand Name",
        fetch_data_error: "Failed to load data. Please try again later.",
        update_success: "Brand updated successfully!",
        update_error: "Failed to update brand. Please try again later.",
        create_success: "Brand created successfully!",
        create_error: "Failed to create brand. Please try again later.",
        product_created_success: "Product created successfully!",
        full_name: "Full Name",
        username: "Username",
        email: "Email",
        password: "Password",
        enter_new_password: "Enter The New Password",
        role: "Role",
        fetch_users_error: "Failed to load users. Please try again later.",
        full_name_placeholder: "Enter your full name",
        username_placeholder: "Enter your username",
        email_placeholder: "Enter your email",
        password_placeholder: "Enter your password",
        user: "User",
        moderator: "Moderator",
        admin: "Admin",
        registering: "Registering...",
        register: "Register",
        login_title: "Login - Cedicor",
        login: "Login",
        forgot_password: "Forgot Password?",
        no_account_register: "Don't have an account? Register",
        login_error:
          "Failed to login. Please check your credentials and try again.",
        register_title: "Register - Cedicor",
        have_account_login: "Have an account? Login",
        registration_success: "Registration successful!",
        registration_error: "Failed to register. Please try again later.",
        last_5_products: "Last 5 Products",
        last_5_sales: "Last 5 Sales",
        total: "Total",
        unit_price: "Unit Price",
        order: "Order",
        invoice: "Invoice",
        print: "Print",
        print_invoice: "Print Invoice",
      },
    },
    es: {
      translation: {
        dashboard: "Tablero",
        products: "Productos",
        orders: "Ordenes",
        app_code: "Codigo App",
        number: "Número",
        order_number: "Número de Orden",
        customer: "Cliente",
        order_type: "Tipo de Orden",
        transport: "Transporte",
        observation: "Observación",
        sold: "Vendido",
        date: "Fecha",
        quantity: "Cantidad",
        yes: "Si",
        no: "No",
        product_list: "Lista de Productos",
        create_product: "Crear Nuevo Producto",
        categories: "Categorías",
        brand_list: "Lista de Marcas",
        create_brand: "Crear Nueva Marca",
        users: "Usuarios",
        user_list: "Lista de Usuarios",
        create_user: "Crear Nuevo Usuario",
        total_auto_parts: "Total de Repuestos",
        orders_completed: "Órdenes Completadas",
        pending_orders: "Órdenes Pendientes",
        recent_activity: "Actividad Reciente",
        description: "Descripción",
        order_completed: "Orden #{{orderId}} completada",
        new_auto_part_added: "Nuevo repuesto añadido: {{part}}",
        order_pending: "Orden #{{orderId}} pendiente",
        code: "Código",
        alt_code: "Código Alternativo",
        price: "Precio",
        stock: "Stock",
        min_quantity: "Cantidad Mínima",
        brand: "Marca",
        actions: "Acciones",
        edit: "Editar",
        delete: "Eliminar",
        loading: "Cargando...",
        fetch_error:
          "No se pudieron cargar los artículos. Por favor, inténtelo más tarde.",
        delete_error: "No se pudo eliminar. Por favor, inténtelo más tarde.",
        confirm_delete: "Esta seguro de querer eliminarlo?",
        select_brand: "Selecciona una marca",
        edit_product: "Editar Producto",
        save_changes: "Guardar Cambios",
        brands: "Marcas",
        id: "ID",
        name: "Nombre",
        fetch_brands_error:
          "No se pudieron cargar las marcas. Por favor, inténtelo más tarde.",
        delete_brand_error:
          "No se pudo eliminar la marca. Por favor, inténtelo más tarde.",
        edit_brand: "Editar Marca",
        brand_name: "Nombre de la Marca",
        fetch_data_error:
          "No se pudo cargar la información. Por favor, inténtelo más tarde.",
        update_success: "¡Actualizado con éxito!",
        update_error: "No se pudo actualizar. Por favor, inténtelo más tarde.",
        create_success: "¡Creado con éxito!",
        create_error: "No se pudo crear. Por favor, inténtelo más tarde.",
        full_name: "Nombre Completo",
        username: "Nombre de Usuario",
        email: "Correo Electrónico",
        password: "Contraseña",
        enter_new_password: "Ingrese La Nueva Contraseña",
        role: "Rol",
        fetch_users_error:
          "No se pudo cargar la lista de usuarios. Por favor, inténtelo más tarde.",
        full_name_placeholder: "Ingresa tu nombre completo",
        username_placeholder: "Ingresa tu nombre de usuario",
        email_placeholder: "Ingresa tu correo electrónico",
        password_placeholder: "Ingresa tu contraseña",
        user: "Usuario",
        moderator: "Moderador",
        admin: "Administrador",
        registering: "Registrando...",
        register: "Registrar",
        login_title: "Iniciar Sesión - Cedicor",
        login: "Iniciar Sesión",
        forgot_password: "¿Olvidaste tu contraseña?",
        no_account_register: "¿No tienes una cuenta? Regístrate",
        login_error:
          "Error al iniciar sesión. Verifica tus credenciales e intenta nuevamente.",
        register_title: "Registro - Cedicor",
        have_account_login: "¿Ya tienes una cuenta? Inicia sesión",
        registration_success: "¡Registro exitoso!",
        registration_error:
          "No se pudo registrar. Por favor, inténtelo más tarde.",
        last_5_products: "Últimos 5 Productos",
        last_5_sales: "Últimas 5 Ventas",
        total: "Total",
        unit_price: "Precio Unitario",
        order: "Orden",
        invoice: "Factura",
        print: "Imprimir",
        print_invoice: "Imprimir Factura",
      },
    },
  },
  lng: savedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
