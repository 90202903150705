import axios from "axios";

// const API_BASE_URL = "http://localhost:8000";
const API_BASE_URL = "https://api-386v.onrender.com";

// Set up Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.pathname = "/";
    }
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = async ({ email, password }) => {
  try {
    const response = await api.post("/api/auth/login", { email, password });
    if (response.data.ok) {
      const { token, user } = response.data;
      localStorage.setItem("user", user);
      localStorage.setItem("token", token);
      localStorage.setItem("role", user.role);
    }
    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export const register = async ({ name, username, email, password, role }) => {
  try {
    const response = await api.post("/api/auth/register", {
      name,
      username,
      email,
      password,
      role,
    });
    localStorage.setItem("user", response.data.user);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("role", response.data.user.role);
    return response.data;
  } catch (error) {
    console.error("Registration failed:", error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

export const getUsers = async () => {
  try {
    const response = await api.get("/api/users");
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const deleteUser = async (id_user) => {
  try {
    const response = await api.delete(`/api/users/delete/${id_user}`);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const updateUser = async (id_user, data) => {
  try {
    const response = await api.put(`/api/users/update/${id_user}`, data);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const response = await api.get("/api/products");
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getOrders = async () => {
  try {
    const response = await api.get("/api/orders");
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getBrands = async () => {
  try {
    const response = await api.get("/api/brands");
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const updateBrand = async (id_brand, data) => {
  try {
    const response = await api.put(`/api/brands/update/${id_brand}`, data);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const createBrand = async (data) => {
  try {
    const response = await api.post(`/api/brands/create`, data);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const deleteBrand = async (id_brand) => {
  try {
    const response = await api.delete(`/api/brands/delete/${id_brand}`);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getProductById = async (id_product) => {
  try {
    const response = await api.get(`/api/products/${id_product}`);
    return response.data[0];
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getOrderById = async (id_order) => {
  try {
    const response = await api.get(`/api/orders/${id_order}`);
    return response.data[0];
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getBrandById = async (id_brand) => {
  try {
    const response = await api.get(`/api/brands/${id_brand}`);
    return response.data[0];
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const updateProduct = async (id_product, data) => {
  try {
    const response = await api.put(`/api/products/update/${id_product}`, data);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const createProduct = async (data) => {
  try {
    const response = await api.post(`/api/products/create`, data);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const deleteProduct = async (id_product) => {
  try {
    const response = await api.delete(`/api/products/delete/${id_product}`);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const deleteOrder = async (id_product) => {
  try {
    const response = await api.delete(`/api/orders/delete/${id_product}`);
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export default api;
