import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/UserList.css";
import { getUsers, deleteUser, updateUser } from "../../config/api"; // Importar funciones de API
import Navmenu from "../../components/Navmenu";
import { useTranslation } from "react-i18next";

const UserList = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingUser, setEditingUser] = useState(null); // Estado para manejar edición

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getUsers();
        setUsers(data);
      } catch (err) {
        console.error("Failed to fetch users", err);
        setError(t("fetch_users_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [t]);

  // Maneja la eliminación de usuarios
  const handleDelete = async (id) => {
    if (window.confirm(t("confirm_delete"))) {
      try {
        await deleteUser(id); // Llamar a la API para eliminar
        setUsers(users.filter((user) => user.id !== id)); // Actualizar la lista local
      } catch (err) {
        console.error("Failed to delete user", err);
        alert(t("delete_error"));
      }
    }
  };

  // Maneja la edición de usuarios
  const handleEdit = (user) => {
    setEditingUser(user); // Establece el usuario actual para editar
  };

  // Maneja la actualización después de editar
  const handleUpdate = async (updatedUser) => {
    try {
      await updateUser(updatedUser.id, updatedUser); // Llamar a la API para actualizar
      setUsers(
        users.map((user) => (user.id === updatedUser.id ? updatedUser : user))
      );
      setEditingUser(null); // Cerrar el modo de edición
    } catch (err) {
      console.error("Failed to update user", err);
      alert(t("update_error"));
    }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <h2 className="text-center my-4">{t("user_list")}</h2>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </div>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center">{error}</div>
          ) : (
            <div className="card shadow-sm p-4">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("full_name")}</th>
                    <th>{t("username")}</th>
                    <th>{t("email")}</th>
                    <th>{t("role")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={user.id}>
                      <td>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary me-2"
                          onClick={() => handleEdit(user)}
                        >
                          {t("edit")}
                        </button>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDelete(user.id)}
                        >
                          {t("delete")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {editingUser && (
            <EditUserModal
              user={editingUser}
              onUpdate={handleUpdate}
              onClose={() => setEditingUser(null)}
            />
          )}
        </main>
      </div>
    </div>
  );
};

// Componente Modal para editar usuario
const EditUserModal = ({ user, onUpdate, onClose }) => {
  const { t } = useTranslation();

  // Estado inicial para el formulario, sin incluir la contraseña
  const [formData, setFormData] = useState({
    ...user,
    password: "", // La contraseña queda vacía
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Excluir el campo `password` si está vacío
    const updatedData = { ...formData };
    if (!updatedData.password) {
      delete updatedData.password;
    }

    onUpdate(updatedData); // Llama a la función de actualización
  };

  return (
    <div className="modal show d-block" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("edit_user")}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">{t("full_name")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t("email")}</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t("password")}</label>
                <input
                  type="text" // Cambiar a tipo password
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder={t("enter_new_password")}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t("role")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                {t("save_changes")}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                {t("cancel")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserList;
