import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/Dashboard.css";
import Navmenu from "../components/Navmenu";
import { useTranslation } from "react-i18next";
import { getOrders, getProducts } from "../config/api";

const Dashboard = () => {
  const { t } = useTranslation();
  const [pendingOrders, setPendingOrders] = useState(0);
  const [completeOrders, setCompleteOrders] = useState(0);
  const [totalsProducts, setTotalsProducts] = useState(0);

  // Ejemplos de datos locales (cámbialos por tus datos reales)
  const [lastFiveProducts, setLastFiveProducts] = useState([
    { id: 101, name: "Filtro de aceite", price: "$10" },
    { id: 102, name: "Pastillas de freno", price: "$20" },
    { id: 103, name: "Bujías", price: "$8" },
    { id: 104, name: "Batería", price: "$100" },
    { id: 105, name: "Amortiguadores", price: "$75" },
  ]);

  const [lastFiveSales, setLastFiveSales] = useState([
    { saleId: 501, orderId: 1234, date: "2024-11-10", total: "$200" },
    { saleId: 502, orderId: 1236, date: "2024-11-11", total: "$150" },
    { saleId: 503, orderId: 1237, date: "2024-11-12", total: "$300" },
    { saleId: 504, orderId: 1238, date: "2024-11-13", total: "$50" },
    { saleId: 505, orderId: 1239, date: "2024-11-14", total: "$400" },
  ]);

  useEffect(() => {
    getOrders().then((data) => {
      const pending = data.filter((order) => order.status === 0);
      const complete = data.filter((order) => order.status === 1);
      setPendingOrders(pending.length);
      setCompleteOrders(complete.length);

      const last5 = data.slice(-5);
      setLastFiveSales(last5);
    });
    getProducts().then((data) => {
      setTotalsProducts(data.length);

      const last5 = data.slice(-5);
      setLastFiveProducts(last5);
    });
  }, []);

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">{t("dashboard")}</h1>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card text-white bg-primary mb-3">
                <div className="card-header">{t("total_auto_parts")}</div>
                <div className="card-body">
                  <h5 className="card-title">{totalsProducts}</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card text-white bg-success mb-3">
                <div className="card-header">{t("orders_completed")}</div>
                <div className="card-body">
                  <h5 className="card-title">{completeOrders}</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card text-white bg-warning mb-3">
                <div className="card-header">{t("pending_orders")}</div>
                <div className="card-body">
                  <h5 className="card-title">{pendingOrders}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">{t("recent_activity")}</div>
                <div className="card-body">
                  <h5 className="mt-4 mb-3">{t("last_5_products")}</h5>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>{t("code")}</th>
                        <th>{t("alt_code")}</th>
                        <th>{t("description")}</th>
                        <th>{t("price")}</th>
                        <th>{t("stock")}</th>
                        <th>{t("min_quantity")}</th>
                        <th>{t("brand")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveProducts && lastFiveProducts.length > 0 && lastFiveProducts.map((product) => (
                        <tr key={product.id}>
                          <td>{product.code}</td>
                          <td>{product.alternative_code}</td>
                          <td>{product.description}</td>
                          <td>{product.price}</td>
                          <td>{product.stock}</td>
                          <td>{product.minimum_quantity}</td>
                          <td>{product.brand && product.brand.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* Últimas 5 Ventas */}
                  <h5 className="mt-4 mb-3">{t("last_5_sales")}</h5>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>{t("app_code")}</th>
                        <th>{t("number")}</th>
                        <th>{t("order_number")}</th>
                        <th>{t("customer")}</th>
                        <th>{t("order_type")}</th>
                        <th>{t("transport")}</th>
                        <th>{t("observation")}</th>
                        <th>{t("sold")}</th>
                        <th>{t("date")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveSales && lastFiveSales.length > 0 && lastFiveSales.map((sale) => (
                        <tr key={sale.id}>
                        <td>{sale.app_code}</td>
                        <td>{sale.number}</td>
                        <td>{sale.order_number}</td>
                        <td>{sale.id_customer}</td>
                        <td>{sale.id_order_type}</td>
                        <td>{sale.id_transport}</td>
                        <td>{sale.observation}</td>
                        <td>{sale.sold === 1 ? t("yes") : t("no")}</td>
                        <td>{sale.date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
