import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/Register.css";
import { register } from "../config/api";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    role: "user",
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await register(formData);
      alert(t("registration_success"));
      window.location.href = "/login";
    } catch (err) {
      console.error("Registration failed", err);
      setError(t("registration_error"));
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div
        className="card shadow-lg p-4"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        <h2 className="text-center mb-4">{t("register_title")}</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              {t("full_name")}
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder={t("full_name_placeholder")}
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              {t("username")}
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder={t("username_placeholder")}
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              {t("email")}
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder={t("email_placeholder")}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              {t("password")}
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder={t("password_placeholder")}
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <button type="submit" className="btn btn-success w-100 mt-3">
              {t("register")}
            </button>
          </div>
          <div className="text-center mt-3">
            <a href="/" className="link-secondary">
              {t("have_account_login")}
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
