import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/InvoiceOrder.css"; // Asegúrate de que este archivo exista
import { getOrderById } from "../config/api";
import { useTranslation } from "react-i18next";

const InvoiceOrder = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const data = await getOrderById(id);
        setOrder(data);
      } catch (err) {
        console.error("Failed to fetch order", err);
        setError(t("fetch_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [id, t]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const calculateTotal = () => {
    return order.ProductOrder.reduce((total, item) => {
      const itemTotal = parseFloat(item.list_price) * item.quantity;
      return total + itemTotal;
    }, 0).toFixed(2);
  };

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border" role="status"></div>
        <p>{t("loading")}...</p>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger text-center mt-5">{error}</div>;
  }

  if (!order) {
    return (
      <div className="alert alert-warning text-center mt-5">{t("no_data")}</div>
    );
  }

  const { number, date, send_date, observation, ProductOrder } = order;

  return (
    <div className="container my-5 invoice-order">
      {/* Botón de impresión */}
      <div className="d-flex justify-content-end mb-3">
        <button
          className="btn btn-light"
          onClick={handlePrint}
          title={t("print_invoice")}
        >
          <i className="fas fa-print"></i> {t("print")}
        </button>
      </div>
      {/* Tabla y contenido */}
      <div id="printable">
        <h2 className="text-center mb-4">
          {t("invoice")} #{number}
        </h2>
        <p>
          <strong>{t("order")}:</strong> #{id}
        </p>
        <p>
          <strong>{t("date")}:</strong> {formatDate(date)}
        </p>
        <p>
          <strong>{t("observation")}:</strong> {observation}
        </p>
        <hr />
        <h2>{t("products")}</h2>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>{t("code")}</th>
                <th>{t("description")}</th>
                <th>{t("quantity")}</th>
                <th>{t("unit_price")}</th>
                <th>{t("total")}</th>
              </tr>
            </thead>
            <tbody>
              {ProductOrder.map((item) => (
                <tr key={item.id}>
                  <td>{item.Product.code}</td>
                  <td>{item.Product.description}</td>
                  <td>{item.quantity}</td>
                  <td>${parseFloat(item.list_price).toFixed(2)}</td>
                  <td>
                    ${(parseFloat(item.list_price) * item.quantity).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h2 className="text-end">
          {t("total")}: ${calculateTotal()}
        </h2>
      </div>
    </div>
  );
};

export default InvoiceOrder;
