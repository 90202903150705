import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/Orders.css";
import { deleteOrder, getOrders } from "../config/api";
import Navmenu from "../components/Navmenu";
import { useTranslation } from "react-i18next";

const Orders = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await getOrders();
        setOrders(data);
      } catch (err) {
        console.error("Failed to fetch orders", err);
        setError(t("fetch_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [t]);

  const handleDelete = async (orderId) => {
    if (window.confirm(t("confirm_delete"))) {
      try {
        await deleteOrder(orderId);
        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );
      } catch (err) {
        console.error("Failed to delete order", err);
        setError(t("delete_error"));
      }
    }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <h2 className="text-center my-4">{t("orders")}</h2>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </div>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center">{error}</div>
          ) : (
            <div className="card shadow-sm p-4">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>{t("app_code")}</th>
                    <th>{t("number")}</th>
                    <th>{t("order_number")}</th>
                    <th>{t("customer")}</th>
                    <th>{t("order_type")}</th>
                    <th>{t("transport")}</th>
                    <th>{t("observation")}</th>
                    <th>{t("sold")}</th>
                    <th>{t("date")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td>{order.app_code}</td>
                      <td>{order.number}</td>
                      <td>{order.order_number}</td>
                      <td>{order.id_customer}</td>
                      <td>{order.id_order_type}</td>
                      <td>{order.id_transport}</td>
                      <td>{order.observation}</td>
                      <td>{order.sold == 1 ? t("yes") : t("no")}</td>
                      <td>{order.date}</td>
                      <td>
                        <a href={`/factura/orden/${order.id}`} className="me-2">
                          <i class="fa-solid fa-file-invoice"></i>
                        </a>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDelete(order.id)}
                        >
                          {t("delete")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Orders;
