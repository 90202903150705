import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/Products.css";
import { deleteProduct, getProducts } from "../config/api";
import Navmenu from "../components/Navmenu";
import { useTranslation } from "react-i18next";

const Products = () => {
  const roleUser = localStorage.getItem("role");
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts();
        setProducts(data);
      } catch (err) {
        console.error("Failed to fetch products", err);
        setError(t("fetch_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [t]);

  const handleDelete = async (productId) => {
    if (window.confirm(t("confirm_delete"))) {
      try {
        await deleteProduct(productId);
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productId)
        );
      } catch (err) {
        console.error("Failed to delete product", err);
        setError(t("delete_error"));
      }
    }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <h2 className="text-center my-4">{t("products")}</h2>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </div>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center">{error}</div>
          ) : (
            <div className="card shadow-sm p-4">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>{t("code")}</th>
                    <th>{t("alt_code")}</th>
                    <th>{t("description")}</th>
                    <th>{t("price")}</th>
                    <th>{t("stock")}</th>
                    <th>{t("min_quantity")}</th>
                    <th>{t("brand")}</th>
                    {roleUser === "admin" && <th>{t("actions")}</th>}
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product.id}>
                      <td>{product.code}</td>
                      <td>{product.alternative_code}</td>
                      <td>{product.description}</td>
                      <td>{product.price}</td>
                      <td>{product.stock}</td>
                      <td>{product.minimum_quantity}</td>
                      <td>{product.brand.name}</td>
                      {roleUser === "admin" && (
                        <td>
                          <a
                            href={`/productos/${product.id}`}
                            className="btn btn-sm btn-warning me-2"
                          >
                            {t("edit")}
                          </a>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDelete(product.id)}
                          >
                            {t("delete")}
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Products;
