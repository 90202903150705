import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./language/i18n";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterAdmin from "./pages/admin/Register";
import Dashboard from "./pages/dashboard";
import UserList from "./pages/admin/UserList";
import Products from "./pages/Products";
import Brands from "./pages/Brands";
import ProductsEdit from "./pages/ProductsEdit";
import BrandsEdit from "./pages/BrandsEdit";
import ProductsCreate from "./pages/ProductsCreate";
import BrandsCreate from "./pages/BrandsCreate";
import Orders from "./pages/Orders";
import InvoiceOrder from "./pages/InvoiceOrder";
import AdminRoute from "./config/AdminRoute";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/admin/register"
            element={
              <AdminRoute>
                <RegisterAdmin />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/user-list"
            element={
              <AdminRoute>
                <UserList />
              </AdminRoute>
            }
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/productos" element={<Products />} />
          <Route
            path="/productos/:id"
            element={
              <AdminRoute>
                <ProductsEdit />
              </AdminRoute>
            }
          />
          <Route
            path="/productos/crear"
            element={
              <AdminRoute>
                <ProductsCreate />
              </AdminRoute>
            }
          />
          <Route path="/marcas" element={<Brands />} />
          <Route
            path="/marcas/:id"
            element={
              <AdminRoute>
                <BrandsEdit />
              </AdminRoute>
            }
          />
          <Route
            path="/marcas/crear"
            element={
              <AdminRoute>
                <BrandsCreate />
              </AdminRoute>
            }
          />
          <Route path="/ordenes" element={<Orders />} />
          <Route path="/factura/orden/:id" element={<InvoiceOrder />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
